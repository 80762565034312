import tree from 'state';
import * as customerEffects from 'state/customers/effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { get } from 'lodash';
import * as effects from './effects';

const supplyInterests = tree.select(['supplyInterests']);

export async function getSupplyInterestsGroupDetails(groupId) {
  const supplyGroup = await asyncTreeRequester({
    func: effects.getSupplyInterestGroupDetails.bind(this, groupId),
    cursor: supplyInterests,
    path: ['group', groupId],
  });

  supplyInterests.set(
    ['group', 'leadSource'],
    get(supplyGroup, 'lead_source', {})
  );

  return supplyGroup;
}

export async function getSupplyInterestsGroupItems(groupId) {
  const response = asyncTreeRequester({
    func: effects.getSupplyInterestsGroupItems.bind(this, groupId),
    cursor: supplyInterests,
    path: ['groupItems', groupId],
  });
  return response;
}

export async function getSupplyInterestsGroupItemsForAgGrid(groupId) {
  const response = asyncTreeRequester({
    func: effects.getSupplyInterestsItemsForAgGrid.bind(
      this,
      {},
      {
        filters: {
          active_status: [1],
          supply_opportunity: [groupId],
          filters: {
            agGridData: { filterModel: {}, startRow: 0, endRow: 100 },
          },
        },
      }
    ),
    cursor: supplyInterests,
    path: ['groupItems', groupId],
  });
  return response;
}

export async function getSupplyCustomer(customerId) {
  try {
    const customer = await customerEffects.getCustomerDetails(customerId);
    supplyInterests.set(['groupCustomer', customerId], customer);
  } catch (err) {
    console.error(err);
  }
}
